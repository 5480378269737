<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" name="form" :model="modelRef" :labelCol="{ span: 4 }" :wrapperCol="{ span: 10 }" @finish="onSubmit">
				<a-form-item label="小程序UI版本" name="uiVersion" :rules="[{ required: true,message: '必填项不允许为空' }]">
					<a-select v-model:value="modelRef.uiVersion" placeholder="请选择小程序UI版本">
						<a-select-option :value="1">默认UI版本</a-select-option>
						<a-select-option :value="2">佳影UI版本</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item label="支付类型" name="payType" :rules="[{ required: true,message: '必填项不允许为空' }]">
					<a-select v-model:value="modelRef.payType" placeholder="请选择支付类型">
						<a-select-option :value="1">汇付支付</a-select-option>
						<a-select-option :value="2">微信支付</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item v-if="modelRef.payType == 2" label="商户号版本" name="wxPayVersion" :rules="[{ required: true,message: '必填项不允许为空' }]">
					<a-select v-model:value="modelRef.wxPayVersion" placeholder="请选择微信商户号版本">
						<a-select-option :value="1">旧商户版本</a-select-option>
						<a-select-option :value="2">新商户版本</a-select-option>
					</a-select>
				</a-form-item>

			<a-form-item label="是否开启全局预警" name="isOpenWarn" extra="注:此开关为全局预警开关,只有次开关开启,影院预警配置才生效">
			  <a-radio-group v-model:value="modelRef.isOpenWarn" name="isWarnGroup">
			    <a-radio :value="1">是</a-radio>
			    <a-radio :value="0">否</a-radio>
			  </a-radio-group>
			</a-form-item>

        <a-form-item label="是否开放一卡/券通" name="isBatchOpen">
          <a-radio-group v-model:value="modelRef.isBatchOpen" name="isBatchOpen">
            <a-radio :value="0">不开放</a-radio>
            <a-radio :value="1">开放</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="是否显示影院入口" name="isDisplayEntrance">
          <a-radio-group v-model:value="modelRef.isDisplayEntrance" name="isDisplayEntranceGroup">
            <a-radio :value="0">否</a-radio>
            <a-radio :value="1">是</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="是否可以选择影院" name="isChooseCinema">
          <a-radio-group v-model:value="modelRef.isChooseCinema" name="isChooseCinemaGroup">
            <a-radio :value="0">否</a-radio>
            <a-radio :value="1">是</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="影院提示语" name="chooseCinemaTip" v-if="modelRef.isChooseCinema == 0">
          <a-textarea v-model:value="modelRef.chooseCinemaTip" placeholder="请输入影院提示语"></a-textarea>
        </a-form-item>

				<a-row>
					<a-col :offset="2" span="20">
						<a-table :dataSource="list" :columns="columns" :pagination="false">
							<template #bodyCell="{ column, record, index }">
								<template v-if="column.key === 'info'">
									<div>
										影院名称：{{ record.name }}
									</div>
									<div>
										影院地址： {{ record.address }}
									</div>
								</template>

								<template v-if="column.key === 'isDisabled'">
									{{ record.isDisabled ? '已禁用' : '已启用' }}
								</template>
								<template v-if="column.key === 'isSend'">
									<a-select v-model:value="record.isUpload" placeholder="请选择是否上报" style="width: 190px;">
										<a-select-option :value="0">不上报</a-select-option>
										<a-select-option :value="1">上报</a-select-option>
									</a-select>
								</template>
							</template>
						</a-table>
					</a-col>
				</a-row>

				<a-form-item style="margin-top: 40px;text-align: center;">
					<a-button v-permission="['system_manage_cinemaSetting_send']" type="primary" html-type="submit">提交</a-button>
				</a-form-item>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import { getCinemaList } from '@/service/modules/cinema.js';
	import { getCinemaSetting, setCinemaSetting, updateCinemaSetting } from '@/service/modules/system.js';
	export default ({
		data() {
			return {
				loading: false,
				modelRef: {
					uiVersion: 1,
					payType: 1,
					wxPayVersion: 1,
					isOpenWarn: 0,
					isDisplayEntrance:0,
					isChooseCinema:0,
					chooseCinemaTip:'',
					isBatchOpen: 0,
				},
				list: [],
				columns: [{
					title: '影院国家编码',
					dataIndex: 'code'
				}, {
					title: '影院名称',
					key: 'info'
				}, {
					title: '是否执行数据上报至统一运营平台',
					key: 'isSend'
				}],
			}
		},
		created() {
			this.getCinemaList()
			this.getData()
		},
		methods: {
			async onSubmit() {
				this.loading = true;
				let cinemaList = this.list.map(item => {
					return {
						id: item.id,
						isUpload: item.isUpload
					}
				});
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				postData.cinemaList = cinemaList;
				let ret = await updateCinemaSetting(postData);
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('修改成功');
					this.getData();
				}
			},
			async getData() {
				this.loading = true;
				let ret = await getCinemaSetting({});
				this.loading = false;
				if (ret.code === 200) {
					this.$store.commit("cinema/setUiVersion", ret.data.uiVersion);
					this.modelRef = ret.data;
				}
			},
			async getCinemaList(){
				this.loading = true;
				try {
					let ret = await getCinemaList({
						page: 1,
						pageSize: 9999,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
				if (!isJpgOrPng) {
					this.$message.error('上传文件格式不正确');
				}
				const isLt1M = file.size / 1024 / 1024 < 2;
				if (!isLt1M) {
					this.$message.error('上传文件太大了');
				}
				return isJpgOrPng && isLt1M;
			},
		}
	})
</script>

<style scoped>
</style>
